import React from "react";
import SixBlocks from "../../../components/editorial/SixBlocks";
import data from '../../../../queries/data/packages';
import Layout from "../../../UI/Layout";
import '../../../scss/packages.scss';

const index = () => {

  const blocks = [...data.data.wp.q.options]

  const dataHeader = data.data.wp.q.dataHeader;

  return (
    <Layout className="Editorial" dataHeader={dataHeader}>
      <SixBlocks blocks={blocks} />
    </Layout>
  )
}

export default index;