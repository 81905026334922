import React from 'react';
import '../../scss/sixBlocks.scss'

const SixBlocks = (props) => {
    const {blocks} = props;
    return (
        <div className='SixBlocks'>
            {blocks && blocks.length>0 && (
                <ul>
                    {blocks.map(itm => (
                        <li key={itm.id}>
                            <div>
                                {itm?.image && <img src={itm.image} />}
                                {itm?.text && <div dangerouslySetInnerHTML={{__html: itm.text}}></div>}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default SixBlocks